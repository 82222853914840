import React, { startTransition, useState } from 'react';
import {
    ORGDataTable,
    ATMButton,
    ATMIcon,
    ATMContainer,
    ATMSegment,
    ATMSelect,
    ATMInput,
    ATMResponsive,
    ATMForm,
    ATMPopover,
} from 'shared-it-appmod-ui';
import moment from 'moment';
import Lang from 'src/libraries/language';
import style from './contracts.module.scss';
import * as _ from 'lodash';
import { Dialog } from './dialog.view';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { getUserType } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';
import { isPrime, isAdmin, isContact } from 'src/libraries/users.library';
import { status } from 'src/constants/common.constant';
import { formatter } from './contracts.component';

const defaultEditObject = {
    contract_number: -1,
    description: '',
    cd_hide: 1,
    contract_id: -1,
    utilityID: -1,
};

export const handleEditModalOpen: any = (
    value,
    setEditCol,
    changeEditObject,
    openEditDialog
) => {
    if (value.spendAllowedAdmin || value.spendAllowed) {
        setEditCol(_.cloneDeep(value));
        const obj = {
            contract_number: Number(value.contract_number),
            description: value.description,
            cd_hide: value.isHideForSpend,
            contract_id: Number(value.contract_id),
            utilityID: Number(value.sempra_company_id),
        };
        changeEditObject(() => {
            const prev1 = obj;
            prev1.cd_hide = obj?.cd_hide ? 1 : 0;
            return prev1;
        });
        openEditDialog(true);
    }
};

export const handleEditModalClear: any = (
    handleEditModalClose,
    editCol,
    setEditCol,
    changeEditObject,
    openEditDialog
) => {
    startTransition(() => {
        handleEditModalClose();
        changeEditObject(defaultEditObject);
    });
    setTimeout(() => {
        startTransition(() =>
            handleEditModalOpen(
                editCol,
                setEditCol,
                changeEditObject,
                openEditDialog
            )
        );
    }, 1);
};

export const handleAddModalClear: any = (
    handleAddModalClose,
    changeAddObject,
    handleAddModalOpen
) => {
    startTransition(() => {
        handleAddModalClose();
        changeAddObject({});
    });
    setTimeout(() => {
        startTransition(() => handleAddModalOpen());
    }, 1);
};

export const handleEditModalDone: any = (
    handleEditModalClose,
    handleConfirmModalOpen,
    setConfirmationType
) => {
    handleEditModalClose();
    handleConfirmModalOpen();
    setConfirmationType('edit');
};

export const handleAddModalDone: any = (
    handleAddModalClose,
    handleConfirmModalOpen,
    setConfirmationType
) => {
    handleAddModalClose();
    handleConfirmModalOpen();
    setConfirmationType('add');
};

export const handleConfirmModalOk: any = (
    confirmationType,
    editChanges,
    editObject,
    addChanges,
    addObject,
    handleConfirmModalClose
) => {
    if (confirmationType === 'edit') {
        delete editObject.utilityID;
        startTransition(() => editChanges(editObject));
    } else if (confirmationType === 'add') {
        delete addObject.utility_id;
        addChanges(addObject);
    }
    handleConfirmModalClose();
};

const ContractsView: any = ({
    data,
    editChanges,
    addChanges,
    fetchData,
    loading,
    handleSearch,
    handleFilter,
    handleClear,
    poNumber,
    maNumber,
    handleDownload,
}) => {
    const [addDialogOpen, openAddDialog] = useState(false);
    const [editDialogOpen, openEditDialog] = useState(false);
    const [confirmDialog, openConfirmDialog] = useState(false);
    const [addObject, changeAddObject] = useState({});
    const [editObject, changeEditObject] = useState(defaultEditObject);
    const [confirmationType, setConfirmationType] = useState('');
    const [editCol, setEditCol] = useState({});
    const isUserPrime = isPrime();
    const isUserAdmin = isAdmin();
    const isUserContact = isContact();
    const user_type = getUserType();

    const handleEditModalClose = () => openEditDialog(false);
    const handleAddModalOpen = () => openAddDialog(true);
    const handleAddModalClose = () => openAddDialog(false);

    const handleConfirmModalOpen = () => openConfirmDialog(true);
    const handleConfirmModalClose = () => openConfirmDialog(false);

    const tableData = data['data'] ? data['data'] : [];
    const colorCode = (
        <div>
            <p style={{ fontSize: '11px' }}>
                <u>
                    <strong>
                        POs against which YOU CAN RECORD SPEND by default, will
                        be these:
                    </strong>
                </u>
            </p>
            <p style={{ fontSize: '11px' }}>
                &emsp;<strong>Expiring in Future Year(s)</strong> - Contract
                expiration date is in future, in the next year or beyond.
            </p>
            <p style={{ fontSize: '11px' }}>
                &emsp;<strong>Expiring in Current Year</strong> - Contract
                expiration date is within the current year.
            </p>
            <p style={{ fontSize: '11px' }}>
                &emsp;<strong>Expired but within Reporting Grace Period</strong>{' '}
                - Contract expiration date is between Yesterday and September
                1st of previous year.
            </p>
            <p style={{ fontSize: '11px' }}>
                &emsp;<strong>Exception Allowed Cases</strong> - Contract is
                either <strong>Expired</strong> or{' '}
                <strong>Expiration Date Not Found</strong> ; but ADMIN has
                allowed spend entry for it.
            </p>
            <p style={{ fontSize: '11px' }}>
                <u>
                    <strong>
                        POs against which YOU CANNOT RECORD SPEND by default,
                        will be these:
                    </strong>
                </u>
            </p>
            <p style={{ fontSize: '11px' }}>
                &emsp;<strong>Expired</strong> - Contract expiration date is
                between January 1st through August 31st of the previous year.
            </p>
            <p style={{ fontSize: '11px' }}>
                &emsp;<strong>Expiration Date Not Found</strong> - There is no
                expiration date recorded.
            </p>
            <p style={{ fontSize: '11px' }}>
                &emsp;<strong>No Recent Payment to Prime</strong> - No payments
                made to Prime against contract as of September of previous year.
            </p>
            <p style={{ fontSize: '11px' }}>
                For any need on spend update <strong>for expired POs</strong>,
                you can contact the Admin users on{' '}
                <u>
                    <span style={{ color: 'blue' }}>
                        sempra_subcontracting@sempra.com
                    </span>
                </u>
            </p>
        </div>
    );

    const columns = [
        {
            title: Lang.TTL_ADMIN_USER_CONFIG_INFO_UTILITY,
            index: 'sempra_company_name',
            sortable: true,
            width: '10%',
        },
        {
            title: Lang.TTL_PRIME_CONTACT_CONTRACT,
            index: 'contract_number',
            sortable: true,
            width: '12%',
            render: (a: boolean, value) => (
                <span
                    style={{ lineBreak: 'anywhere' }}
                >{`${value?.contract_number}`}</span>
            ),
        },

        {
            title: Lang.LBL_CONTRACTS_PROJECT_DESC,
            index: 'description',
            sortable: true,
            headerProps: {
                className: style.descriptionWidth,
            },
            width: '13%',
        },
        {
            title: Lang.TTL_PRIME_CONTACT_DATE_ADDED,
            index: 'dateCreated',
            sortable: true,
            render: (a: boolean, value) => (
                <span>{`${value?.dateCreated == null ? '-' : new Date(value?.dateCreated).toISOString().split('T')[0] != '1900-01-01' ? moment(value?.dateCreated).zone(0).format('M/D/YYYY') : '-'}`}</span>
            ),
            width: '10%',
        },
        {
            title: (
                <span>
                    Latest Payment <br />
                    To Prime
                </span>
            ),
            index: 'invoiceDate',
            sortable: true,
            render: (a: boolean, value) => (
                <span>{`${value?.invoiceDate == null ? '-' : new Date(value?.invoiceDate).toISOString().split('T')[0] != '1900-01-01' ? moment(value?.invoiceDate).zone(0).format('M/D/YYYY') : '-'}`}</span>
            ),
            width: '10%',
            isVisible: !isUserPrime,
        },
        {
            title: Lang.TTL_PRIME_CONTACT_DATE_EXPI,
            index: 'expiryDate',
            sortable: true,
            render: (a: boolean, value) => (
                <span>{`${value?.expiryDate == null ? '-' : new Date(value?.expiryDate).toISOString().split('T')[0] != '1900-01-01' ? moment(value?.expiryDate).zone(0).format('M/D/YYYY') : '-'}`}</span>
            ),
            width: '10%',
        },
        {
            title: 'PO Creation Date',
            index: 'poCreationDate',
            sortable: true,
            render: (a: boolean, value) => (
                <span>{`${value?.poCreationDate == null ? '-' : new Date(value?.poCreationDate).toISOString().split('T')[0] != '1900-01-01' ? moment(value?.poCreationDate).zone(0).format('M/D/YYYY') : '-'}`}</span>
            ),
            width: '10%',
        },

        {
            title: (
                <span>
                    Total Spend Amount <br />
                    Per PO
                </span>
            ),
            index: 'totalSpendAmt',
            sortable: true,
            render: (a: boolean, value) => (
                <span>{`${formatter.format(value?.totalSpendAmt)}`}</span>
            ),
            width: '10%',
        },
        {
            // title: (<span>Contract Valid for<br /> Spend Entry</span>),
            title: Lang.TTL_PRIME_CONTACT_VALID_FOR_SPEND_ENTRY,
            index: 'spendAllowed',
            sortable: true,
            render: (a: boolean, value) => (
                <span>
                    <span>{`${value?.spendAllowedAdmin ? 'Yes' : value?.spendAllowed ? 'Yes' : 'No'}`}</span>
                    <span
                        style={
                            value?.spendAllowedAdmin && !value?.isValidContract
                                ? {
                                      background: '#06bb3d',
                                      marginLeft: '10px',
                                      padding: '2px 3px',
                                      color: '#fff',
                                      borderRadius: '2px',
                                  }
                                : {}
                        }
                    >{`${value?.spendAllowedAdmin && !value?.isValidContract ? 'Exception Allowed' : ''}`}</span>
                </span>
            ),
            // width:'10%'
        },
        {
            title: (
                <span style={{ position: 'relative' }}>
                    {/* {Lang.TTL_PRIME_CONTACT_VALIDY_EXPLANATION} */}
                    Validity Explanation
                    <ATMPopover
                        trigger={
                            <i
                                className={`info circle icon`}
                                style={{
                                    color: '#848080',
                                    position: 'absolute',
                                    right: '-22px',
                                }}
                            ></i>
                        }
                        content={colorCode}
                        size="huge"
                        position="bottom right"
                        flowing={true}
                        style={{ marginLeft: '0px', marginTop: '10px' }}
                    />
                </span>
            ),
            width: '22%',
            index: 'validityExplanation',
            sortable: true,
        },

        {
            title: (
                // Lang.TTL_PRIME_CONTACT_VIEW_ON_UPDATE_SPEND_SCREEN
                <span>
                    View on Update
                    <br /> Spend Screen?
                </span>
            ),
            index: 'isHideForSpend',
            sortable: true,
            render: (a: boolean, value) => (
                <span>{`${value?.isHideForSpend ? 'No' : 'Yes'}`}</span>
            ),
            width: '5%',
        },
        // {
        //     title: Lang.LBL_CONTRACTS_STATUS,
        //     index: 'hide',
        //     sortable: true,
        //     render: (a: boolean, value) => (
        //         <span style={{ display: 'flex', alignItems: 'center' }}>
        //             <span
        //                 className={
        //                     !value.hide
        //                         ? style.activeContract
        //                         : style.inactiveContract
        //                 }
        //             ></span>
        //             {`${!value.hide ? Lang.LBL_ACTIVE : Lang.LBL_INACTIVE}`}
        //         </span>
        //     ),
        // },
        {
            title: Lang.LBL_CONTRACTS_EDIT,
            index: 'actions',
            sortable: false,
            width: '10%',
            render: (a: boolean, value) => (
                <a
                    style={
                        value?.spendAllowedAdmin || value?.spendAllowed
                            ? { cursor: 'pointer' }
                            : { cursor: 'not-allowed' }
                    }
                    onClick={() =>
                        handleEditModalOpen(
                            value,
                            setEditCol,
                            changeEditObject,
                            openEditDialog
                        )
                    }
                >
                    <ATMIcon
                        name="edit outline"
                        style={
                            value?.spendAllowedAdmin || value?.spendAllowed
                                ? { color: '#606060' }
                                : { color: '#ccc' }
                        }
                    />
                </a>
            ),
        },
    ];

    if (isUserAdmin || isUserContact) {
        columns.pop();
    }

    const utilityData = data?.utilityData;

    const handleEditModalClearCall = () =>
        handleEditModalClear(
            handleEditModalClose,
            editCol,
            setEditCol,
            changeEditObject,
            openEditDialog
        );

    const handleAddModalClearCall = () => {
        handleAddModalClear(
            handleAddModalClose,
            changeAddObject,
            handleAddModalOpen
        );
    };

    const handleEditModalDoneCall = () =>
        handleEditModalDone(
            handleEditModalClose,
            handleConfirmModalOpen,
            setConfirmationType
        );

    const handleAddModalDoneCall = () =>
        handleAddModalDone(
            handleAddModalClose,
            handleConfirmModalOpen,
            setConfirmationType
        );
    return (
        <>
            {confirmDialog && (
                <Confirm
                    header={Lang.LBL_CONFIRMATION}
                    content={'Please confirm your changes.'}
                    onConfirm={() =>
                        handleConfirmModalOk(
                            confirmationType,
                            editChanges,
                            editObject,
                            addChanges,
                            addObject,
                            handleConfirmModalClose
                        )
                    }
                    onCancel={() => handleConfirmModalClose()}
                    open={confirmDialog}
                    size="tiny"
                    confirmButton="Confirm"
                    btn_text="Confirm"
                />
            )}

            <Dialog
                handleModalClose={handleEditModalClose}
                handleModalClear={handleEditModalClearCall}
                handleModalDone={handleEditModalDoneCall}
                changeObject={changeEditObject}
                type={'edit'}
                open={editDialogOpen}
                editObject={editObject}
                utilityData={data?.utilityData}
            />

            <Dialog
                handleModalClose={handleAddModalClose}
                handleModalClear={handleAddModalClearCall}
                handleModalDone={handleAddModalDoneCall}
                changeObject={changeAddObject}
                type={'add'}
                open={addDialogOpen}
                utilityData={data?.utilityData}
            />

            <ATMContainer fluid className={style.wrapper}>
                {isUserPrime && (
                    <div // visible onlt to Prime user
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '10px',
                        }}
                    >
                        <div className={style.pageTitle}>
                            {Lang.LBL_CONTRACTS}
                        </div>
                        <div>
                            <ATMButton
                                primary
                                floated="right"
                                onClick={() => handleAddModalOpen()}
                                className="plusIcon"
                            >
                                <ATMIcon name="plus" className="secondary" />

                                {Lang.TTL_ADD_CONTRACTS}
                            </ATMButton>
                            <ATMButton
                                secondary
                                floated="right"
                                onClick={() => handleDownload()}
                            >
                                {Lang.LBL_DOWNLOAD}
                            </ATMButton>
                        </div>
                    </div>
                )}

                <ATMSegment>
                    {/* <ATMContainer fluid>
                        <ATMGrid>
                            <ATMGrid.Row className={style.headerRow}>
                                <div className={style.reportFiltersWrapper}>
                                    <label>
                                        <b>{Lang.LBL_PURCHASE_RELEASE_ORDER}</b>
                                    </label>

                                    <ATMSelect
                                        placeholder="All"
                                        options={purchaseOrderOptions}
                                        value={poNumber}
                                        onChange={(_, val) => {
                                            handleFilter('poNumber', val.value);
                                        }}
                                    />
                                </div>
                                <div
                                    className={style.reportFiltersWrapper}
                                    style={{ paddingLeft: '10px' }}
                                >
                                    <label>
                                        <b>{Lang.LBL_MASTER_AGGREMENT}</b>
                                    </label>
                                    <ATMSelect
                                        placeholder="All"
                                        options={masterAgreementOptions}
                                        value={maNumber}
                                        onChange={(_, val) => {
                                            handleFilter('maNumber', val.value);
                                        }}
                                    />
                                </div>
                                <div
                                    className={style.reportFiltersWrapper}
                                    style={{ paddingLeft: '10px' }}
                                >
                                    <ATMButton
                                        color="blue"
                                        secondary
                                        onClick={handleClear}
                                    >
                                        {Lang.LBL_CLEAR}
                                    </ATMButton>
                                    <ATMButton
                                        color="blue"
                                        primary
                                        onClick={handleSearch}
                                    >
                                        {Lang.LBL_SEARCH}
                                    </ATMButton>
                                </div>
                            </ATMGrid.Row>
                        </ATMGrid>
                    </ATMContainer> */}

                    <ORGDataTable
                        celled={false}
                        data={tableData}
                        columns={columns}
                        total={data?.total_rows}
                        history
                        counter
                        onChange={fetchData}
                        rowsPerPageOptions={[10, 20, 25]}
                        loading={loading}
                        sortable
                        filters={true}
                        filteredBy={(filterList) =>
                            (filterList || []).map(
                                (filter) =>
                                    Lang.CONTRACT_FILTER_LABEL[filter.name] ??
                                    filter.name
                            )
                        }
                    >
                        {/* {() => ({
                            filters: ({ values, setValue, setError }) => (
                                <ATMGrid
                                    columns={4}
                                    className={style.filters}
                                    doubling
                                    stackable
                                >
                                    <ATMGrid.Column>
                                        <label>
                                            {Lang.TTL_PRIME_CONTACT_CONTRACT}
                                        </label>
                                        <ATMInput
                                            fluid
                                            value={values?.contract_number}
                                            name="contract_number"
                                            placeholder={
                                                Lang.TTL_PRIME_CONTACT_CONTRACT
                                            }
                                            onChange={(_, val) =>
                                                setValue(
                               m                     'contract_number',
                                                    val.value
                                                )
                                            }
                                        />
                                    </ATMGrid.Column>

                                    <ATMGrid.Column>
                                        <label>
                                            {
                                                Lang.TTL_ADMIN_USER_CONFIG_INFO_UTILITY
                                            }
                                        </label>
                                        <ATMSelect
                                            fluid
                                            placeholder={Lang.LBL_SELECT}
                                            name="sempra_company_name"
                                            value={
                                                values?.sempra_company_name
                                                    ? parseInt(
                                                          values?.sempra_company_name
                                                      )
                                                    : ''
                                            }
                                            options={utilityData?.map(
                                                (type) => ({
                                                    key: type?.Sempra_Company_ID,
                                                    value: type?.Sempra_Company_ID,
                                                    text: type?.Sempra_Company_Name,
                                                })
                                            )}
                                            onChange={(_, val) => {
                                                setValue(
                                                    'sempra_company_name',
                                                    val.value
                                                );
                                            }}
                                        />
                                    </ATMGrid.Column>
                                    {/* <ATMGrid.Column>
                                        <label>
                                            {Lang.LBL_CONTRACTS_STATUS}
                                        </label>
                                        <ATMSelect
                                            fluid
                                            placeholder={
                                                Lang.LBL_CONTRACTS_STATUS
                                            }
                                            name="hide"
                                            value={
                                                values.hide
                                            }
                                            options={status?.map(
                                                (item) => ({
                                                    key: item.key,
                                                    value: item.value,
                                                    text: item.text,
                                                })
                                            )}
                                            onChange={(_, val) => {
                                                setValue(
                                                    'hide',
                                                    val.value
                                                );
                                            }}
                                        />
                                    </ATMGrid.Column> */}
                        {/* </ATMGrid> */}
                        {/* ),
                        })} */}

                        {() => ({
                            filters: {
                                contract_number: ({ value, setValue }) => (
                                    <>
                                        <ATMResponsive greaterThan="mobile">
                                            {' '}
                                            <ATMForm.Field>
                                                <span
                                                    className={
                                                        style.filterSection
                                                    }
                                                >
                                                    {
                                                        Lang.TTL_PRIME_CONTACT_CONTRACT
                                                    }
                                                </span>
                                                <ATMInput
                                                    fluid
                                                    value={value}
                                                    name="contract_number"
                                                    placeholder={
                                                        Lang.TTL_PRIME_CONTACT_CONTRACT
                                                    }
                                                    onChange={(_, val) =>
                                                        setValue(val.value)
                                                    }
                                                />
                                            </ATMForm.Field>
                                        </ATMResponsive>
                                    </>
                                ),
                                sempra_company_name: ({ value, setValue }) => (
                                    <>
                                        <ATMResponsive greaterThan="mobile">
                                            {' '}
                                            <ATMForm.Field>
                                                <span
                                                    className={
                                                        style.filterSection
                                                    }
                                                >
                                                    {
                                                        Lang.TTL_ADMIN_USER_CONFIG_INFO_UTILITY
                                                    }
                                                </span>
                                                <ATMSelect
                                                    fluid
                                                    placeholder={
                                                        Lang.LBL_SELECT
                                                    }
                                                    name="sempra_company_name"
                                                    value={
                                                        value
                                                            ? parseInt(value)
                                                            : ''
                                                    }
                                                    options={utilityData?.map(
                                                        (type) => ({
                                                            key: type?.Sempra_Company_ID,
                                                            value: type?.Sempra_Company_ID,
                                                            text: type?.Sempra_Company_Name,
                                                        })
                                                    )}
                                                    onChange={(_, val) =>
                                                        setValue(val.value)
                                                    }
                                                />
                                            </ATMForm.Field>
                                        </ATMResponsive>
                                    </>
                                ),
                            },
                        })}
                    </ORGDataTable>
                </ATMSegment>
            </ATMContainer>
        </>
    );
};
export default ContractsView;
